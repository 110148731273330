import { Component, createResource } from 'solid-js'
import { A } from '@solidjs/router'
import { getIsSubscribed } from '~/services/stripe/subscription'
import styles from '~/components/header/header.module.scss'
import StarIcon from '~/assets/icons/favorite.svg'
import { translations } from '~/translations'
import { isNewUser, remainingDaysOffered } from '~/services/database/users'

const SubscribeButton: Component = () => {
  const [isSubscribed] = createResource(getIsSubscribed)
  const label = () => isSubscribed.latest ? translations().header.subscription : translations().header.subscribe
  const route = () => isSubscribed.latest ? '/account/subscriptions' : '/subscribe'

  return (
    <A class={styles.menuEntry} href={route()}>
      <StarIcon class={styles.icon} />
      <span>{label()}</span>
      {isNewUser() && (
        <div class={styles.promo}>
          {translations().subscriptions.newAccount.days(remainingDaysOffered().toString())}
        </div>
      )}
    </A>
  )
}

export default SubscribeButton
